import React, { useState } from "react"
import Layout from "../components/_App/layout"
import SEO from "../components/_App/seo"
import Navbar from "../components/_App/Navbar"
import Footer from "../components/_App/Footer"
import PageBanner from "../components/Common/PageBanner"

export default function Profile() {
  return (
    <Layout>
      <SEO title="Dashboard" />

      <Navbar />

      <PageBanner pageTitle="Dashboard" />
      <Footer />
    </Layout>
  )
}
